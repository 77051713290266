import * as React from "react";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";

import * as styles from "./404.module.scss";

function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <Head title="404: Not found" />
      <div className={styles.container}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist…</p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
